import logo from "./logo.svg";
import "./App.css";
import { useState } from "react";
import RequestBlock from "./RequestBlock";

function App() {
  const [globalUnit, setGlobalUnit] = useState(`KB`);
  const [globalServerUrl, setGlobalServerUrl] = useState(
    `https://router-worker.adamyang.workers.dev`
  );
  const [globalServerPath, setServerPath] = useState(`/transfer`);
  return (
    <div className="App">
      <RequestBlock
        gloablServerUrl={globalServerUrl}
        globalUnit={globalUnit}
        globalServerPath={globalServerPath}
      ></RequestBlock>
    </div>
  );
}

export default App;
