import { useState } from "react";

const RequestBlock = (props) => {
  const [size, setSize] = useState(1);
  const [sizeUnit, setSizeUnit] = useState(props.globalUnit || "KB");
  const [serverUrl, setServerUrl] = useState(
    props.gloablServerUrl || `https://router-worker.adamyang.workers.dev`
    // `http://localhost:8787`
  );
  const [serverPath, setServerPath] = useState(
    props.globalServerPath || `/transfer`
  );
  const [requestUrl, setRequestUrl] = useState(serverUrl + serverPath + "/");
  const [startEnd, setStartEnd] = useState({ start: 0, end: 0 });
  const [delay, setDelay] = useState(0);
  return (
    <div>
      <p>
        Current Size: {size} {sizeUnit}
      </p>
      <div>
        {/* <p>input to Change Size Unit: (KB, MB, GB) &nbsp; &nbsp; </p> */}
        <p>
          input to Change Size: &nbsp; &nbsp;{" "}
          <input
            onChange={(e) => {
              let temp = e.target.value.length === 0 ? 1 : e.target.value;
              setSize(temp);
              setRequestUrl(serverUrl + serverPath + "/" + temp);
            }}
          />
        </p>
      </div>
      <div>
        URL: &nbsp;
        <a href={requestUrl}>{requestUrl}</a>
      </div>
      <div style={{ marginTop: "20px" }}>
        <button
          style={{
            backgroundColor: "#007BFF", // Primary blue color
            color: "white", // Text color
            padding: "10px 20px", // Padding around the text
            border: "none", // No border
            borderRadius: "5px", // Rounded corners
            cursor: "pointer", // Hand cursor on hover
            fontSize: "16px", // Font size
            transition: "background-color 0.3s", // Smooth transition for hover effect
            outline: "none", // Remove outline which browsers add by default on focus
          }}
          onClick={async () => {
            try {
              var task_start = performance.now();
              const response = await fetch(requestUrl, { mode: "cors" });
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              var task_end = performance.now();
              setStartEnd({ start: task_start, end: task_end });
              setDelay(task_end - task_start);
            } catch (error) {
              console.error("Fetch error: ", error.message);
            }
          }}
        >
          Fire!
        </button>
        &nbsp; start: {startEnd.start} end: {startEnd.end} elpased: {delay}
      </div>
    </div>
  );
};

export default RequestBlock;
